var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoading}},[_c('b-card',{staticClass:"white-bg",scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"outline-secondary","to":{ name: 'UserIndex' }}},[_c('font-awesome-icon',{attrs:{"icon":"angle-left"}}),_vm._v(" Regresar")],1),(_vm.user.id)?_c('b-button',{staticClass:"mr-2",attrs:{"variant":"secondary","to":{ name: 'UserShow', params: { id: _vm.user.id }}}},[_c('font-awesome-icon',{attrs:{"icon":"search"}}),_vm._v(" Detalle ")],1):_vm._e(),_c('b-button',{attrs:{"type":"submit","form":"user-form","variant":"primary"}},[_c('font-awesome-icon',{attrs:{"icon":"save"}}),_vm._v(" Guardar")],1)],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('b-form',{attrs:{"id":"user-form"},on:{"submit":function($event){$event.preventDefault();return passes(_vm.saveUser)}}},[_c('h5',[_vm._v("Datos generales")]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Nombre","invalid-feedback":"Ingresa el nombre"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Nombre","state":errors[0] ? false : null},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Usuario","invalid-feedback":"Ingresa el usuario"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Usuario","state":errors[0] ? false : null},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1)]}}],null,true)})],1),_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Correo","invalid-feedback":"Ingresa el correo"}},[_c('b-form-input',{attrs:{"autocomplete":"false","placeholder":"Correo","state":errors[0] ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)]}}],null,true)})],1)],1),_c('b-row',{staticClass:"mb-2"},[_c('b-col',{staticClass:"text-left",attrs:{"cols":"12","md":"4"}},[_c('ValidationProvider',{attrs:{"rules":_vm.$route.name === 'UserNew' || _vm.$route.name === 'PortalUserNew' ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Contraseña"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"autocomplete":"false","type":_vm.showPassword ? 'text' : 'password',"placeholder":"Contraseña","state":errors[0] ? false : null},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"border-top-right-radius":".25rem !important","border-bottom-right-radius":".25rem !important"},attrs:{"variant":errors[0] ? 'outline-danger' : 'outline-primary',"title":"Mostrar contraseña"},on:{"click":function($event){_vm.showPassword = true},"mouseout":function($event){_vm.showPassword = false}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1)],1),_c('b-form-invalid-feedback',{staticClass:"text-left"},[_vm._v("Ingresa la contraseña")])],1)],1)]}}],null,true)})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }