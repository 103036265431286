<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <ValidationObserver ref="observer" v-slot="{ passes }">
                <b-form @submit.prevent="passes(saveUser)" id="user-form">
                    <h5>Datos generales</h5>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Nombre" invalid-feedback="Ingresa el nombre">
                                    <b-form-input v-model="user.name" autocomplete="false" placeholder="Nombre"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Usuario" invalid-feedback="Ingresa el usuario">
                                    <b-form-input v-model="user.username" autocomplete="false" placeholder="Usuario"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <b-form-group label="Correo" invalid-feedback="Ingresa el correo">
                                    <b-form-input v-model="user.email" autocomplete="false" placeholder="Correo"
                                                  :state="errors[0] ? false : null"></b-form-input>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                    <b-row class="mb-2">
                        <b-col cols="12" md="4" class="text-left">
                            <ValidationProvider :rules="$route.name === 'UserNew' || $route.name === 'PortalUserNew' ? 'required' : ''" v-slot="{ errors }">
                                <b-form-group label="Contraseña">
                                    <b-input-group>
                                        <b-form-input v-model="user.password" autocomplete="false" :type=" showPassword ? 'text' : 'password'"
                                                      placeholder="Contraseña" :state="errors[0] ? false : null"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :variant="errors[0] ? 'outline-danger' : 'outline-primary'" v-b-tooltip.hover
                                                      title="Mostrar contraseña" @click="showPassword = true" @mouseout="showPassword = false"
                                                      style="border-top-right-radius: .25rem !important; border-bottom-right-radius: .25rem !important;">
                                                <font-awesome-icon icon="eye"></font-awesome-icon>
                                            </b-button>
                                        </b-input-group-append>
                                        <b-form-invalid-feedback class="text-left">Ingresa la contraseña</b-form-invalid-feedback>
                                    </b-input-group>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-form>
            </ValidationObserver>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'UserIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'UserShow', params: { id: user.id }}" v-if="user.id" class="mr-2">
                        <font-awesome-icon icon="search"></font-awesome-icon> Detalle
                    </b-button>
                    <b-button type="submit" form="user-form" variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Guardar</b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { userUrl } from '@routes';
import * as constants from '@constants';

extend('required', {
    ...required,
    message: 'Campo requerido'
});

export default {
    data() {
        return {
            user: {
                id: 0,
                name: '',
                username: '',
                email: '',
                phone1: '',
                phone2: '',
                password: '',
                role: 'ROLE_BACK_OFFICE_USER',
                groups: [],
                jobPosition: ''
            },
            roles: [
                { text: 'Normal', value: 'ROLE_BACK_OFFICE_USER' },
                { text: 'Administrador', value: 'ROLE_BACK_OFFICE_ADMIN' }
            ],
            groups: [],
            userType: '',
            showPassword: false,
            userGroups: [],
            swalTitle: 'Usuarios',
            breadcrumb: {
                title: 'Usuarios',
                path: [
                    {
                        name: 'UserIndex',
                        text: 'Usuarios'
                    }
                ]
            },
            action: 'Nuevo',
            signature: null,
            oldSignature: null,
            isLoading: false,
            file: null
        };
    },
    created() {
        if (this.$route.name.includes('Edit')) {
            this.loadData();

            this.action = 'Modificar';
        }

        this.breadcrumb.path.push({
            name: this.$route.name,
            text: `${this.action}`
        });

        this.$emit('update-breadcrumb', this.breadcrumb);
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(userUrl(), {
                params: {
                    id: this.$route.params.id,
                    application: this.userType
                }
            }).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let user = response.data.user;

                    this.user.id = user.id;
                    this.user.name = user.name;
                    this.user.username = user.username;
                    this.user.email = user.email;
                    this.user.phone1 = user.phone1;
                    this.user.phone2 = user.phone2;
                    this.user.groups = user.groups;
                    this.user.jobPosition = user.jobPosition;

                    this.oldSignature = user.signature ?? null;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        },
        saveUser() {
            this.isLoading = true;

            let formData = new FormData();

            formData.append('file', this.file);

            let data = {
                application: this.userType,
                user: {
                    id: this.user.id,
                    name: this.user.name,
                    email: this.user.email,
                    phone1: this.user.phone1,
                    phone2: this.user.phone2,
                    username: this.user.username,
                    password: this.user.password, // TODO send always or only if not empty?
                    jobPosition: this.user.jobPosition
                }
            };

            formData.append('data', JSON.stringify(data));

            // const method =  this.user.id === 0 ? 'post' : 'put';

            this.axios.post(userUrl(), formData).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    this.$root.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'success'
                    });

                    this.$router.push({ name: 'UserShow', params: { id: response.data.userId }});
                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.swalTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                console.error(error);

                this.$bvToast.toast('Ocurrió un error al guardar los cambios.', {
                    title: this.swalTitle,
                    variant: 'danger'
                });
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    },
    watch: {
    }
};
</script>

<style scoped>
</style>